<template>
    <div class='app-container'>
        
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="送审单位:">
                <el-select v-model="search.institution" filterable size="small" clearable placeholder="请选择状态"
                    class="form-line-item">
                    <el-option v-for="item in institutionList" :key='item.institutionId' :value="item.institutionId"
                        :label="item.institutionName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="margin-left:10px;">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                    搜索
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                    重置
                </el-button>
            </el-form-item>
        </el-form>
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="mini" icon="el-icon-plus" @click="insertReviewHandle">添加</el-button>
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table class="mytable-scrollbar reportList" round align="left" ref="paperTable"
                highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                :row-config="{isHover: true,isCurrent: true}"
                :data="reportList">
                <!-- <vxe-column type='seq' title="序号" width="80" :show-overflow="'tooltip'"></vxe-column> -->
                <vxe-column field="reportId" title="ID" width="200" tree-node></vxe-column>
                <!-- <el-tree
                    :data="treeData"
                    :props="defaultProps"
                    :load="loadNode"
                    :highlight-current="true"
                    node-key="id"
                    show-checkbox
                    :default-checked-keys="checkedKeys"
                    ref="tree"
                    accordion
                    @node-click="handleNodeClick">
                </el-tree> -->
                <vxe-column field="institutionName" title="送审单位" width="300" :show-overflow="'tooltip'" sortable></vxe-column>
                <vxe-column field="reportName" title="质量分析报告名称" width="400" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column title="操作" min-width="200">

                    <template #default="{row}">
                        <el-button size="small" type="text" @click="routeTemplateCustom(row)">质量分析报告定制
                        </el-button>
                        <el-popconfirm title="确定删除吗？" @confirm='removeReport(row)' style="margin-left:10px;">
                            <!-- <el-button >删除</el-button> -->
                            <el-button slot="reference" style="color:red;" size="small" type="text">删除</el-button>
                        </el-popconfirm>
                        <!-- <el-button size="small" type="text" @click="insertReviewHandle" style="margin-left:10px;">添加子单位</el-button> -->
                    </template>
                </vxe-column>
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <vxe-modal v-model="insertReportModel" :position="{top: '0px'}" width="800" :show-footer='true' show-zoom
            resize :transfer='true'>
            <template #title>
                添加质量分析报告模板
            </template>
            <vxe-form :data="insertReport" title-align="right" title-width="100">
                <vxe-form-item fieldname="institutionId" title="送审单位" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.institutionId" filterable placeholder="请选择" @change="institutionChangeHandle"
                            size="large" transfer>
                            <vxe-option v-for="item in institutionList" :key='item.institutionId'
                                :value="item.institutionId" :label="item.institutionName"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
         
                <!-- <vxe-form-item title="批次:">
                    <template #default="{ data }">
                        <vxe-select v-model="data.batchId" size='large' clearable placeholder="请选择批次"
                            class="form-line-item" :disabled="data.lock">
                            
                        <vxe-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                            :value="item.batchId"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item> -->

                <vxe-form-item fieldname="reportName" title="报告名称" :span="20" :item-render="{}">
                        <template #default="{ data }">
                            <el-input placeholder="请输入名称"  v-model="data.reportName" :disabled="true"  clearable></el-input>
                        </template>
                </vxe-form-item>
            </vxe-form>
            <template>
                <el-table
                    ref="multipleTable"
                    :data="batchList"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="id"  width="120">
                        <template slot-scope="scope">{{ scope.row.batchId }}</template>
                    </el-table-column>
                    <el-table-column prop="batchName" label="批次名称" width="280"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip></el-table-column>
                </el-table>
            </template>
            <template #footer>
                <el-button @click="InsertReport" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
    export default {
        name: 'reportMenu',
        data() {
            return {
                loading: false,
                insertReportModel: false,
                batchShow: true,
                insertReport: {
                    // templateName: '',
                    institutionId: '',
                    institutionName:'',
                    batchId:'',
                    reportName: '',
                    lock:true
                },
                search: {
                    institution: ''
                },
                reportList: [],
                //分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 20],
                    total: 100
                },
                institutionList: [],
                batchList: [], //所有的batch
                // insertBatchIdList: [], //选中的batchid
                degreeList: [],
                reviewList: [],
                treeData: [],
                checkedKeys: [],
                multipleSelectionBatch: [],
            }
        },
        created() {
            this.getReportList();
            this.getAllInstitutions();
            // this.getDegree();
            // this.getAllPatterns();
        },
        methods: {
            batchSearch() {
                this.getReportList();
            },
            resetHandle() {
                this.search = {
                    institution: ''
                }
            },
            insertReviewHandle() {
                this.insertReportModel = true;
            },
            
            InsertReport() {
                // let params = this.insertReport;
                if(this.multipleSelectionBatch.length==0){
                    this.$message.warning("请选择批次！");
                    return;
                }
                let insertBatchIdList=[];
                this.multipleSelectionBatch.forEach((item,index) => {
                    // console.log(self);
                    insertBatchIdList.push(item.batchId);
                });
                let params = {
                    institutionId: this.insertReport.institutionId,
                    reportName: this.insertReport.reportName,
                    batchIds: insertBatchIdList,
                }
                // console.log(params)
                // return ;
                this.$api.schoolCustom.postAddReport(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("添加成功");
                            this.getReportList();
                            this.insertReportModel = false;
                        } else {
                            this.$message.warning("添加失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },

            //获取report表
            getReportList() {
                this.loading = true;
                let params = {
                    institutionId: this.search.institution
                };
                console.log(params)
                this.$api.schoolCustom.getReportList(params)
                    .then(res => {
                        this.loading = false;
                        if (res.data.code == 200) {
                            // console.log(res.data.data);
                            this.reportList = res.data.data;
                            // console.log(12,this.reportList);
                            this.page.total = this.reportList.length;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            getAllInstitutions() {
                let params = {
                    pageIndex: 1,
                    pageSize: 20000
                }
                this.$api.schoolCustom.getAllInstitutions(params)
                    .then(res => {
                        this.institutionList = res.data.data;
                        // this.page.pageCount = res.data.count;
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            // 获取送审单位的批次
            getBatch(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId)
                this.$api.schoolCustom.getBatch(param)
                    .then(res => {
                        // console.log(res.data.data);
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            institutionChangeHandle(val) {
                let value = val.value;
                this.insertReport.batchId = '';
                if (value == '') {
                    // console.log(val)
                    this.insertReport.lock = true;
                    return;
                }
                this.insertReport.lock = false;
                this.getBatch(value);
                this.getInstitutionName(value);
                // console.log(this.batchShow);
            },
            getInstitutionName(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId)
                this.$api.schoolCustom.getInstitutionName(param)
                    .then(res => {
                        // console.log(res.data);
                        this.insertReport.institutionName = res.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //获取已选择的批次信息
            handleSelectionChange(val) {
                this.multipleSelectionBatch = val;
                // console.log(this.multipleSelectionBatch);
                let name = '';
                for(let i=0;i<this.multipleSelectionBatch.length;i++){
                    name += this.multipleSelectionBatch[i].batchId;
                    if(i != this.multipleSelectionBatch.length-1){
                        name += ",";
                    }

                }
                // name += "质量分析报告";
                name = name + this.insertReport.institutionName + "质量分析报告";
                this.insertReport.reportName = name;
                // console.log(this.reportName);
                // console.log(val);
            },
            getAllPatterns() {
                this.$api.reviewCustom.getAllPatterns()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.reviewList = res.data.data;
                            // this.insertReportModel = false;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            routeTemplateCustom(row) {
                this.$store.dispatch('custom/toggleInstitution', {
                    first: row.institutionName,
                    second: row.reportName
                });
                // this.$router.push('/ReportlCustom');
                // console.log(row)
                this.$router.push({
                    name: 'ReportCustom',
                    params: {
                        id: row.reportId
                    }
                })
            },
            // 删除模板
            removeReport(row) {
                let params = {
                    reportId: row.reportId
                };
                this.$api.schoolCustom.postDeleteReport(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功！")
                            this.getReportList();
                        } else {
                            this.$message.warning("删除失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护!");
                    })
            },
            //表格刷新
            refresh() {
                this.getReportTemplatePreviews();
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                this.page.pageIndex = currentPage;
                this.page.pageSize = pageSize;
                this.getReportTemplatePreviews();
            },
            firstPage() {
                this.page.pageIndex = 1;
                this.getReportTemplatePreviews();
            },
            endPage() {
                this.page.pageIndex = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
                    .pageSize == 0 ? 0 : 1);
                this.getReportTemplatePreviews();
            },
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

<style>
    .reportList .vxe-table--body-wrapper {
        height: calc(100vh - 300px);
    }
</style>